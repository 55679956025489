import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import Slack from '../components/integrations/Slack';
import AmberScore from "../components/reusable/AmberScore";

const slack = () => (
  <Layout extra_class={'mg-top-80'}>
    <SEOComponent title="Amber by inFeedo | Integrations" />
    <Slack/>
    <AmberScore/>
  </Layout>
)

export default slack;
