import React, { Component } from 'react';
import { isMobileDevice } from "../../helper/isMobileDevice";
import classnames from 'classnames';
//assets
import bottom_page from '../../../static/images/integrations/bottom_page.png';
import slack_bottom_mobile from '../../../static/images/integrations/slack_bottom_mobile.png';
import slack_side from '../../../static/images/integrations/slack_side.png';
import slack_mobile from '../../../static/images/integrations/Slack_Mobile.svg';
import slack_logo from '../../../static/images/integrations/slack_logo.svg';

export default class Slack extends Component {
    
    state = {
        is_mobile: false
    }

    componentDidMount(){
        if (isMobileDevice()) {
            this.setState({
                is_mobile: true
            })
        }
    }

    openSlack = () => {
        window.open('https://slack.com/oauth/v2/authorize?client_id=3650913171.1205054351395&scope=chat:write,users:read,users:read.email&user_scope=');
    }

    openSlackVideo = () => {
        window.open('https://www.loom.com/share/43826d122ffa4c959d610628a57a2151');
    }

    render() {
        const {is_mobile} = this.state;
        return (
            <div className={classnames('columns flex-wrap no-margin-left integrations', {'is-fullwidth': is_mobile})}>
                <div className={classnames('column is-12 columns no-padding-integrations no-margin-left center-align-items', {'no-margin': is_mobile})}>
                    <div className={classnames('column is-5 columns flex-wrap no-margin-left no-padding-left', {'no-margin no-padding': is_mobile})}>
                        <div className={classnames("is-12 column mg-top-n-30", {'pd-left-70': !is_mobile}, {'has-text-centered no-padding-left no-padding' : is_mobile})}>
                            <p className={classnames("has-text-weight-bold line-height-1 mg-bottom-20 integrations-heading-color", {'font-48 is-pulled-left': !is_mobile}, {'is-3 title': is_mobile})}>
                                Reachout<br/> where your<br/> people are!
                            </p>
                            <p className={classnames("is-pulled-left integrations-sub-text-color", {'font-18': !is_mobile}, {'font-14 mg-left-right-10': is_mobile})}>
                                    With our slack integration, Amber brings employee engagement, learning, wellbeing and knowledge discovery, directly into the natural flow of people’s work. No more missing out. No more low response rates.
                            </p>
                        </div>
                        <div className={classnames("is-11 column columns", {'flex-wrap no-padding no-margin has-text-centered': is_mobile}, {'pd-left-70 has-text-left': !is_mobile})}>
                            <button className={classnames('button has-text-weight-bold', {'column is-2 width-170 mg-left-15': !is_mobile, 'is-inline-block width-190 mg-top-10': is_mobile})} onClick={this.openSlack}>
                                <img className="mg-right-10" width="15" src={slack_logo} alt="Bottom"/> Add to Slack
                            </button>
                            <button className={classnames("button has-text-weight-bold", {'mg-left-10 column is-2 width-170': !is_mobile}, {'mg-top-10 is-inline-block width-190 mg-bottom-20': is_mobile})} onClick={this.openSlackVideo}>
                                Watch Demo
                            </button>
                        </div>
                    </div>
                    <div className="column is-7 no-padding-integrations">
                        <img className={classnames("mg-bottom-30", {'is-pulled-right': !is_mobile}, {'is-pulled-left': is_mobile})} src={is_mobile ? slack_mobile : slack_side} alt="Slack"/>
                    </div>
                </div>
                <div className="column is-12 columns flex-wrap no-margin-integrations no-padding-bottom">
                    { is_mobile ? null
                       : <div className="column is-12 no-padding-bottom no-padding-left">
                            <div className="has-text-centered font-12 has-text-weight-medium">Benefits</div>
                            <div className="has-text-centered font-24 has-text-weight-bold">Maximize employee engagement with Amber</div>
                        </div>}
                    <div className="column is-12 no-padding-top has-text-centered no-padding-bottom">
                        <img src={is_mobile ? slack_bottom_mobile : bottom_page} alt="Bottom"/>
                    </div>
                </div>
            </div>
        )
    }
}
